import { omit, toLower } from 'lodash';

import { type CustomObject, type CustomObjectListDetails, type BulkOverwriteCreateRequest } from '@amalia/core/types';
import { FormatsEnum } from '@amalia/data-capture/fields/types';
import { type CustomObjectDefinition } from '@amalia/data-capture/record-models/types';
import { CustomObjectsApiClient } from '@amalia/data-capture/records/api-client';
import { type CurrencySymbolsEnum } from '@amalia/ext/iso-4217';
import { isCurrencyValue } from '@amalia/kernel/monetary/types';

const MAX_TABLE_DATA = 500;
const KEYWORD = ['clean', 'delete', 'CLEAN', 'DELETE'] as const;

export type CsvRow = Record<string, string>;

interface ITablePreview {
  id: string;
  column: string;
  value: boolean | number | string;
  sourceValue: boolean | number | string;
  type: boolean;
  symbol?: CurrencySymbolsEnum;
}

export const retrieveCustomObject = async (csv: CsvRow[], obj: CustomObjectDefinition) => {
  let objectRelated: CustomObjectListDetails | null = null;
  if (csv.length > 0) {
    const idReference = Object.keys(csv[0])[0];
    const ids = csv.map((one) => one[idReference]).join(',');
    objectRelated = await CustomObjectsApiClient.getObjects(
      obj.machineName,
      {
        page: 0,
        limit: csv.length,
      },
      null,
      true,
      ids,
    );
  }
  return objectRelated;
};

export const getTheRightMachineName = (
  objectDefinition: CustomObjectDefinition,
  columnName: string,
): string | undefined => {
  const validMachineNameOnLower: string[] = Object.keys(objectDefinition.properties).map((prop) => toLower(prop));
  const indexOfValidMachineOnLower = validMachineNameOnLower.indexOf(toLower(columnName));
  const validMachineName =
    indexOfValidMachineOnLower !== -1 ? Object.keys(objectDefinition.properties)[indexOfValidMachineOnLower] : null;
  return validMachineName?.toString();
};

/**
 * Function : create table view form CSV
 * @param csv
 * @param objectdefinition
 * @returns ITablePreview
 */

export const tablePreviewFormaterUtils = async (csv: CsvRow[], objectdefinition: CustomObjectDefinition) => {
  const table: ITablePreview[] | null = [];
  let error: {
    severity: 'error' | 'warning';
    title?: string;
    message: string;
  } | null = null;
  if (csv.length > 0) {
    const properties = objectdefinition.properties;
    const idReference = Object.keys(csv[0])[0];
    const ids = csv.map((row) => row[idReference]).filter((e, i, a) => a.indexOf(e) !== i);
    const result = {};
    let test = csv;

    try {
      const customObject = await retrieveCustomObject(csv, objectdefinition);

      // ATTEMPT TO OVERWRITE NAMEFIELD
      if (objectdefinition.nameField && Object.keys(csv[0]).includes(objectdefinition.nameField)) {
        error = {
          severity: 'error',
          title: 'You are attempt to overwrite the nameField',
          message: objectdefinition.nameField,
        };
        test = [];
      }

      if (
        Object.keys(csv[0])
          .filter((col) => col === idReference)
          .filter((col) => !Object.keys(properties).includes(col)).length > 0
      ) {
        error = {
          severity: 'error',
          title: 'Record ID not found',
          message: idReference,
        };
        test = [];
      }

      // ID PROVIDED NOT FOUND IN OBJECT DEFINITION
      if (customObject && customObject.totalItems > 0 && customObject.totalItems !== csv.length) {
        const badId = csv
          .filter((row) => !customObject.items.find((obj) => obj.externalId === row[idReference]))
          .map((item) => item[idReference]);

        error = {
          severity: 'warning',
          title: `${badId.length > 1 ? 'Some' : 'An'} ID provided ${badId.length > 1 ? 'are' : 'is'} not found in "${
            objectdefinition.name
          }"`,
          message: badId.join(' - '),
        };
        test = csv.filter((row) => !badId.includes(row[idReference]));
      }

      // NO DATA FOUND IN OBJECTDEFINITION
      if (!customObject?.totalItems) {
        error = {
          severity: 'error',
          title: 'No data found',
          message: `Try to load valid data in "${objectdefinition.name}"`,
        };
        test = [];
      }

      // DUPLICATE ROW ID
      if (ids.length > 0) {
        error = {
          severity: 'warning',
          title: 'Duplicate Record ID',
          message: `Try to load ${ids.join(' - ')} once under "${idReference}" column`,
        };
        test = csv.filter((row) => !ids.includes(row[idReference]));
      }

      // COLUMN NOT FOUND IN OBJECT DEFINTION
      if (
        Object.keys(csv[0])
          .filter((col) => col !== idReference)
          .filter(
            (column) =>
              !Object.keys(properties)
                .map((prop) => toLower(prop))
                .includes(toLower(column)),
          ).length > 0
      ) {
        const allBads = Object.keys(csv[0])
          .filter((col) => col !== idReference)
          .filter(
            (column) =>
              !Object.keys(properties)
                .map((prop) => toLower(prop))
                .includes(toLower(column)),
          );
        error = {
          severity: 'warning',
          title: `${allBads.length > 1 ? 'Some' : 'A'} ${allBads.length > 1 ? 'columns' : 'column'}
            provided  ${allBads.length > 1 ? 'are' : 'is'} invalid in ${objectdefinition.name}`,
          message: `${allBads.join(' - ')}`,
        };
        test = csv.map((row) => omit(row, ...allBads));
      }

      test.map((row) =>
        Object.keys(row).map((column) => {
          if (column !== idReference && row[column] !== '') {
            const rightMachineName: string = getTheRightMachineName(objectdefinition, column) || column;
            const sourceValue = customObject?.items.find((list) => list.externalId === row[idReference])?.[
              rightMachineName as keyof CustomObject
            ];
            const overwriteValue = row[column];

            table.push({
              id: row[idReference],
              column: rightMachineName,
              value: overwriteValue,
              sourceValue: sourceValue as number | string,
              type: objectdefinition.properties[rightMachineName]?.format === FormatsEnum.currency,

              symbol: customObject?.items.find((list) => list.externalId === row[idReference])?.content[
                'currencyIsoCode'
              ] as CurrencySymbolsEnum | undefined,
            });
          }
          return result;
        }),
      );

      if (table.length >= MAX_TABLE_DATA) {
        error = {
          severity: 'warning',
          title: 'Too much data!',
          message: `You have more than ${MAX_TABLE_DATA} data to overwrites`,
        };
      }
    } catch (e) {
      return {
        status: { severity: 'error' as const, title: 'Unexpected error', message: (e as Error).message },
        data: [],
      };
    }
  }
  return { status: error, data: table };
};

/**
 *
 * @param csv
 * @param data
 * @param objectDefinition
 * @returns OverwriteRequest
 */

export const createAnOverwritableFormat = async (
  csv: CsvRow[],
  data: ITablePreview[],
  objectDefinition: CustomObjectDefinition,
) => {
  const overwriteToRequest: BulkOverwriteCreateRequest[] = [];
  if (csv.length > 0 && data.length > 0) {
    const customObject = await retrieveCustomObject(csv, objectDefinition);

    if (customObject && customObject.totalItems > 0 && customObject.items.length > 0) {
      data.forEach((item) => {
        const result: BulkOverwriteCreateRequest = {
          field: item.column,
          appliesToExternalId: item.id,
          overwriteValue: {
            [item.column]: KEYWORD.includes(item.value.toString())
              ? toLower(item.value.toString())
              : item.value.toString(),
          },
        };

        const customObjectItem = customObject.items.at(0)?.content[item.column];

        if (
          objectDefinition.properties[item.column]?.format === FormatsEnum.currency &&
          isCurrencyValue(customObjectItem)
        ) {
          result.overwriteValue = {
            [item.column]: KEYWORD.includes(item.value.toString())
              ? item.value.toString().toLowerCase()
              : {
                  value: Number(item.value),
                  symbol: customObjectItem.symbol,
                },
          };
        }
        overwriteToRequest.push(result);
      });
    }
  }
  return overwriteToRequest;
};
