import { css, useTheme } from '@emotion/react';
import { IconWorld } from '@tabler/icons-react';
import { memo } from 'react';
import { FormattedMessage } from 'react-intl';
import { match } from 'ts-pattern';

import { TokenType, type Variable } from '@amalia/amalia-lang/tokens/types';
import { Group, Tooltip, Typography, UnstyledButton } from '@amalia/design-system/components';
import { ColorCategory, TypographyVariant } from '@amalia/design-system/meta';
import { type Plan } from '@amalia/payout-definition/plans/types';

import { AssignedPlansDropdownList, type OnClickPlan } from '../assigned-plans-dropdown-list/AssignedPlansDropdownList';

import { useUsedInPlans } from './useUsedInPlans';

export type UsedInPlansIconProps = {
  readonly tokenId: Variable['id'] | null;
  readonly tokenType: TokenType | null;

  readonly isGlobalToken: boolean;

  readonly plansList: Plan[];

  readonly onClickPlan?: OnClickPlan;
};

export const UsedInPlansIcon = memo(function UsedInPlansIcon({
  isGlobalToken,
  plansList,
  tokenId,
  tokenType,
  onClickPlan,
}: UsedInPlansIconProps) {
  const theme = useTheme();
  const usedInPlans = useUsedInPlans(tokenId, plansList);

  if (!tokenId || !tokenType || !isGlobalToken) {
    return null;
  }

  const isUsedInAtLeastAPlan = usedInPlans.length;

  return (
    <AssignedPlansDropdownList
      plans={usedInPlans}
      onClickPlan={onClickPlan}
    >
      {({ isOpen: isDropdownOpen }) => (
        <UnstyledButton
          css={css`
            flex: none;
            padding: 2px 4px;
            border-radius: ${theme.ds.borderRadiuses.squared};
            background-color: ${isDropdownOpen ? theme.ds.colors[ColorCategory.PRIMARY][50] : 'inherit'};

            transition: ${theme.ds.transitions.default('background-color')};

            &:active,
            &:hover,
            &:focus-visible {
              background-color: ${theme.ds.colors[ColorCategory.PRIMARY][50]};
            }
          `}
        >
          <Tooltip
            isOpen={isDropdownOpen ? false : undefined}
            content={match(tokenType)
              .with(TokenType.FIELD, () => (
                <FormattedMessage defaultMessage="This calculated column is usable in multiple plans.{br}Any change made will apply to those plans." />
              ))
              .with(TokenType.FILTER, () => (
                <FormattedMessage defaultMessage="This table is usable in multiple plans.{br}Any change made will apply to those plans." />
              ))
              .with(TokenType.QUOTA, () => (
                <FormattedMessage defaultMessage="This quota is usable in multiple plans.{br}Any change made will apply to those plans." />
              ))
              .with(TokenType.RULE, () => (
                <FormattedMessage defaultMessage="This rule is usable in multiple plans.{br}Any change made will apply to those plans." />
              ))
              .with(TokenType.VARIABLE, () => (
                <FormattedMessage defaultMessage="This variable is usable in multiple plans.{br}Any change made will apply to those plans." />
              ))
              .with(TokenType.LINK, () => (
                <FormattedMessage defaultMessage="This link is usable in multiple plans.{br}Any change made will apply to those plans." />
              ))
              .otherwise(() => (
                <FormattedMessage defaultMessage="This variable is usable in multiple plans.{br}Any change made will apply to those plans." />
              ))}
          >
            <Group
              align="center"
              gap={4}
            >
              <IconWorld
                size={14}
                color={
                  isUsedInAtLeastAPlan
                    ? theme.ds.colors[ColorCategory.PRIMARY][500]
                    : theme.ds.colors[ColorCategory.GRAY][800]
                }
              />

              {!!isUsedInAtLeastAPlan && (
                <Typography
                  variant={TypographyVariant.BODY_SMALL_REGULAR}
                  css={css`
                    color: ${theme.ds.colors[ColorCategory.PRIMARY][500]};
                  `}
                >
                  {usedInPlans.length}
                </Typography>
              )}
            </Group>
          </Tooltip>
        </UnstyledButton>
      )}
    </AssignedPlansDropdownList>
  );
});
