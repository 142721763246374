import { partition } from 'lodash';
import { memo, useCallback, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { generatePath } from 'react-router-dom';

import { routes } from '@amalia/core/routes';
import {
  ButtonLink,
  DropdownList,
  type DropdownListProps,
  type DropdownListItem,
  Button,
} from '@amalia/design-system/components';
import { useBoolState } from '@amalia/ext/react/hooks';
import { Link } from '@amalia/ext/react-router-dom';
import { ActionsEnum, SubjectsEnum } from '@amalia/kernel/auth/shared';
import { useAbilityContext } from '@amalia/kernel/auth/state';
import { type Plan } from '@amalia/payout-definition/plans/types';

import { IconPlanVisibility } from '../plan-visibility/icons/IconPlanVisibility';

export type OnClickPlan = (planId: string) => Promise<void>;

export type AssignedPlansDropdownListProps = Omit<DropdownListProps, 'items'> & {
  readonly plans: Pick<Plan, 'archived' | 'id' | 'isHidden' | 'name'>[];
  readonly onClickPlan?: OnClickPlan;
};

export const AssignedPlansDropdownList = memo(function AssignedPlansDropdownList({
  plans,
  children,
  onClickPlan,
  ...props
}: AssignedPlansDropdownListProps) {
  const ability = useAbilityContext();

  const { isLoading, setLoadingTrue, setLoadingFalse } = useBoolState(false, 'loading');

  const onClickPlanProxy = useCallback(
    async (planId: string) => {
      try {
        setLoadingTrue();
        await onClickPlan?.(planId);
      } finally {
        setLoadingFalse();
      }
    },
    [onClickPlan, setLoadingTrue, setLoadingFalse],
  );

  const mapPlanToListItem = useCallback(
    (plan: AssignedPlansDropdownListProps['plans'][number]): DropdownListItem => ({
      icon: <IconPlanVisibility isPlanHidden={plan.isHidden} />,
      key: plan.id,
      label: plan.name,
      rightActions:
        ability.can(ActionsEnum.modify, SubjectsEnum.Plan) &&
        (onClickPlan ? (
          <Button
            disabled={isLoading}
            size={Button.Size.SMALL}
            variant={Button.Variant.PRIMARY_TEXT}
            onClick={() => onClickPlanProxy(plan.id)}
          >
            <FormattedMessage defaultMessage="View" />
          </Button>
        ) : (
          <ButtonLink
            size={ButtonLink.Size.SMALL}
            variant={ButtonLink.Variant.PRIMARY_TEXT}
            to={
              <Link
                openInNewTab
                to={generatePath(routes.PLAN_HUB_RULES, { planId: plan.id })}
              />
            }
          >
            <FormattedMessage defaultMessage="View" />
          </ButtonLink>
        )),
    }),
    [isLoading, ability, onClickPlanProxy, onClickPlan],
  );

  const plansListItems = useMemo(() => {
    const [activePlans, archivedPlans] = partition(plans, (plan) => !plan.archived);

    return [
      ...activePlans.map(mapPlanToListItem),
      {
        label: <FormattedMessage defaultMessage="Archived plans" />,
        items: archivedPlans.map(mapPlanToListItem),
        initialIsOpen: false,
      },
    ];
  }, [plans, mapPlanToListItem]);

  return (
    <DropdownList
      {...props}
      disabled={props.disabled || !plansListItems.length}
      items={plansListItems}
    >
      {children}
    </DropdownList>
  );
});
